// src/pages/Services/Services.js
import React from 'react';
import ServiceCard from '../../components/ServiceCard/ServiceCard'; // Assurez-vous de mettre le bon chemin pour votre import
import servicesData from '../../db/servicesData';
import './Services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2 className="services-title">Nos Services</h2>
      <div className="services-list">
        {servicesData.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            image={service.image}
            description={service.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;
