/*
import { products } from '../db/localDB';
export const getProducts = () => {
  return products;
};
*/


// services/ProductService.js

import { PART_API_BASE_URLS } from '../config/api-constants/index';
import { API_BASE_URL } from '../config/setting/index';

const ProductService = {
  getAllProducts: async () => {
    try {
      const response = await fetch(API_BASE_URL + PART_API_BASE_URLS.GetListProducts);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getProductById: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/Product/${id}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch product with id ${id}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  createProduct: async (product) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/Product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      });
      if (!response.ok) {
        throw new Error('Failed to create product');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateProduct: async (id, product) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/Product/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(product),
      });
      if (!response.ok) {
        throw new Error(`Failed to update product with id ${id}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteProduct: async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/Product/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error(`Failed to delete product with id ${id}`);
      }
      return response.ok;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default ProductService;
