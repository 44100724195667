export const products = [
    {
      id: '1',
      name: 'Produit 1',
      description: 'Description du produit 1',
      price: 100,
      image: 'https://via.placeholder.com/150'
    },
    {
      id: '2',
      name: 'Produit 2',
      description: 'Description du produit 2',
      price: 200,
      image: 'https://via.placeholder.com/150'
    },
    {
      id: '3',
      name: 'Produit 3',
      description: 'Description du produit 3',
      price: 300,
      image: 'https://via.placeholder.com/150'
    },
    {
      id: '4',
      name: 'Produit 4',
      description: 'Description du produit 2',
      price: 200,
      image: 'https://via.placeholder.com/150'
    },
    {
      id: '5',
      name: 'Produit 5',
      description: 'Description du produit 3',
      price: 300,
      image: 'https://via.placeholder.com/150'
    },
  ];
  