// src/components/ServiceCard/ServiceCard.js
import React from 'react';
import './stylesServiceCard.css';

const ServiceCard = ({ title, image, description }) => {
  return (
    <div className="service-card">
      <img src={image} alt={title} className="service-card__image" />
      <div className="service-card__content">
        <h3 className="service-card__title">{title}</h3>
        <p className="service-card__description">{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
