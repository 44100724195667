import React from 'react';

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerText}>© 2024 TSE Consultant INT</div>
      <div style={styles.linksContainer}>
        <a href="/services" style={styles.link}>Services</a>
        <span style={styles.separator}>|</span>
        <a href="/contact" style={styles.link}>Contact</a>
      </div>
      <div style={styles.footerText}>
        Email: <a href="mailto:tse.consultant.int@gmail.com" style={styles.link}>tse.consultant.int@gmail.com</a> | Téléphone: <a href="tel:+21652172703" style={styles.link}>+216 52 172 703</a>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    padding: '1.5rem',
    backgroundColor: '#1e2228',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: '0.9rem',
  },
  footerText: {
    marginBottom: '0.5rem',
  },
  linksContainer: {
    margin: '0.5rem 0',
  },
  link: {
    color: '#61dafb',
    textDecoration: 'none',
    margin: '0 0.5rem',
    transition: 'color 0.3s ease',
  },
  separator: {
    color: '#aaa',
  },
  linkHover: {
    color: '#21a1f1',
  }
};

export default Footer;
