const servicesData = [
  {
      id: 1,
      title: 'Consultation en informatique',
      image: require('../assets/img/consulting.png'), // Utilisation correcte de require
      resume: 'TSE Consltant INT intervient dans les problématiques de nos clients pour éclairer le chemin de la solution adéquate.',
      description: 'Notre service de consultation en informatique va au-delà des simples conseils ; c\'est une collaboration stratégique pour propulser votre entreprise vers l\'ère numérique. Nous comprenons que chaque entreprise est unique, c\'est pourquoi notre approche est personnalisée pour répondre à vos défis spécifiques et tirer parti des opportunités numériques.',
  },
  {
      id: 2,
      title: 'Développement d\'applications',
      image: require('../assets/img/app-development.png'), // Chemin relatif correct
      resume: 'Nous développons des applications web, mobile et desktop.',
      description: 'Dans notre bureau, le développement d\'applications va au-delà de la simple écriture de code. C\'est l\'art de transformer vos idées en des expériences numériques exceptionnelles. Nous créons des applications qui non seulement fonctionnent de manière fiable, mais qui inspirent également l\'engagement et la satisfaction des utilisateurs.',
  },
  {
      id: 3,
      title: 'Projets IoT',
      image: require('../assets/img/internet-of-things.png'), // Correction du chemin
      resume: 'De la conceptualisation à la réalisation, notre bureau excelle dans la concrétisation de projets IoT, fusionnant innovation et expertise pour façonner l\'avenir connecté.',
      description: 'Au cœur de notre bureau réside une expertise distinctive dans la réalisation de projets IoT, un concept simple qui transforme votre environnement quotidien en une expérience connectée et intelligente. L\'IoT, ou Internet des Objets, représente la connexion de dispositifs physiques à Internet, permettant une communication transparente et un contrôle à distance.',
  },
  {
      id: 4,
      title: 'Intégration des Systèmes Informatiques',
      image: require('../assets/img/integration.png'), // Utilisation correcte de require
      resume: 'Notre bureau de consulting travaille sur l\'intégration des systèmes informatiques de notre client.',
      description: 'L\'intégration des systèmes informatiques est le maillon fort de notre expertise chez TSE Consultant INT. Nous comprenons que la fluidité des opérations est cruciale pour la performance globale de votre entreprise. C\'est pourquoi notre équipe spécialisée s\'engage à unifier vos systèmes, optimiser les processus et propulser votre entreprise vers l\'excellence opérationnelle.',
  },
];  

export default servicesData;
