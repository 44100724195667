import React from 'react';

const AboutUs = () => {
  return (
    <section id="about" style={styles.section}>
      <h2>À propos de nous</h2>
      <p>Nous sommes un bureau de conseil et de services d'ingénierie en informatique dédié à fournir des solutions innovantes et de haute qualité.</p>
    </section>
  );
};

const styles = {
  section: {
    padding: '2rem',
    backgroundColor: '#f4f4f4',
  },
};

export default AboutUs;
