import React, { useState, useEffect } from 'react';
import './Home.css';
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import servicesData from '../../db/servicesData';
import { products } from '../../db/localDB';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import welcomeImage from '../../assets/img/welcomeImage.jpeg';

const Home = () => {
  const [services, setServices] = useState([]);
  const [produits, setProducts] = useState([]);

  useEffect(() => {
    // Fetch services and products from the local DB
    setServices(servicesData);
    setProducts(products);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className="home-container">
      <section className="section welcome-section">
        <div className="welcome-section-content">
          <h2>Bienvenue sur TSE Consultant INT</h2>
          <p>
            TSE Consultant INT, est un bureau de conseil et de services d'ingénierie en informatique.
            Nous sommes présents en Tunisie (Tunis, Béjà et Chebba - Mahdia). Notre engagement envers
            l'excellence et l'innovation guide notre équipe multidisciplinaire dans la fourniture de
            solutions de consultation et de développement.
          </p>
        </div>
        <div className="welcome-section-image">
          <img src={welcomeImage} alt="Welcome" />
        </div>
      </section>

      {/* Carousel Section */}
      <section className="section carousel-section">
        <h2>Nos Nouveautés</h2>
        <ResponsiveCarousel 
          showThumbs={false} 
          autoPlay 
          interval={3000} 
          infiniteLoop 
          className="carousel-root"
        >
          <div className="carousel-slide">
            <img src={require('../../assets/img/NewOpening.jpeg')} alt="Slide 1" />
            <p className="legend">Nous ouvrons nos portes à El Mourouj 5</p>
          </div>
          <div className="carousel-slide">
            <img src={require('../../assets/img/NewOpening.jpeg')} alt="Slide 2" />
            <p className="legend">Nous ouvrons nos portes à El Mourouj 5</p>
          </div>
          <div className="carousel-slide">
            <img src={require('../../assets/img/NewOpening.jpeg')} alt="Slide 3" />
            <p className="legend">Nous ouvrons nos portes à El Mourouj 5</p>
          </div>
        </ResponsiveCarousel>
      </section>

      {/* Nos Services Section */}
      <section className="section services-section">
        <h2>Nos Services</h2>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={15000}
          keyBoardControl
          containerClass="carousel-container"
        >
          {services.map(service => (
            <div key={service.id} className="service-card">
              <img src={service.image} alt={service.title} className="service-icon" />
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </Carousel>
      </section>

      {/* Products Carousel Section */}
      <section className="section products-carousel-section">
        <h2>Nos Produits</h2>
        <ResponsiveCarousel 
          showThumbs={false} 
          autoPlay 
          interval={5000} 
          infiniteLoop 
          className="carousel-root"
        >
          {produits.map(product => (
            <div key={product.id} className="carousel-slide">
              <img src={product.image} alt={product.name} />
              <p className="legend">{product.name}</p>
            </div>
          ))}
        </ResponsiveCarousel>
        <Link to="/products" className="btn">Voir tous les produits</Link>
      </section>

      {/* Nos Partenaires Section */}
      <section className="section partners-section">
        <h2>Nos Partenaires</h2>
        <div className="partners">
          <img src={require('../../assets/img/NewOpening.jpeg').default} alt="Partner 1" />
          <img src={require('../../assets/img/NewOpening.jpeg').default} alt="Partner 2" />
          <img src={require('../../assets/img/NewOpening.jpeg').default} alt="Partner 3" />
        </div>
      </section>
    </div>
  );
};

export default Home;
