import React, { useState, useEffect } from 'react';
import './Products.css';
import ProductService from '../../services/productService';

const Products = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const defaultImage = 'https://via.placeholder.com/150';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await ProductService.getAllProducts();
        setProducts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="loading-message">
        <i className="fas fa-spinner fa-spin icon"></i>
        Chargement...
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        <i className="fas fa-exclamation-triangle icon"></i>
        Erreur: {error}
      </div>
    );
  }

  return (
    <section id="products" className="products-section">
      <h2>Nos Produits</h2>
      <input
        type="text"
        placeholder="Rechercher un produit..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
      <div className="product-list">
        {filteredProducts.map(product => (
          <div key={product.id} className="card">
            <img
              src={product.image ? product.image : defaultImage}
              alt={product.name}
              className="image"
            />
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p className="price price-license">Prix Licence: Selon les fonctionnalités à choisir</p>
            <p className="price price-subscription">Prix abonnement: Selon pack choisi</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Products;
