




export const PART_API_BASE_URLS = {  
  
  GetListProducts : "/api/Product/externe-get-list-products",
    
    // Ajoutez d'autres URLs d'API ici
  };